import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { isBlank } from 'underscore.string';
import NoSsr from '@material-ui/core/NoSsr';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { removeTypename } from 'shared/helpers/utils';

import { HorizontalDivider } from 'components/core';
import CategoryTilesGrid from 'components/category-tiles-grid';
import { MainContent } from 'components/skip-to-main';
import { DesktopOnly, MobileOnly } from 'shared/components';

import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { getHtmlPlainText } from 'shared/utils/misc-utils';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { AmplitudeEvents, useAmplitude } from 'shared/hooks/use-amplitude';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import useCart from 'shared/hooks/use-cart';

import { MenuImageBanner } from 'src/components/menu-image-banner';
import { SponsoredBrandCarousel } from 'src/components/carousels/ads-carousels/sponsored-brand-carousel';
import { PersonalizedProducts } from 'components/carousels/products-carousels/personalized-products-carousel';

import { useHasValidSponsoredBrandPlacement } from 'src/utils/ads/hooks/sponsored-brand';
import { useProductsForHomePage } from 'src/dispensary/hooks/use-products-for-home-page';
import MenuBanner from './components/menu-banner';

const HomepageCarouselSections = dynamic(() =>
  import('components/homepage-carousel-sections').then((mod) => mod.HomepageCarouselSections)
);
const CategoriesCarouselSlider = dynamic(() => import('components/categories-carousel-slider'));

export default function CoreMenuDispensaryPage() {
  const UI = useUI();
  const { dispensary } = useDispensary();
  const { categories } = useDispensaryCategories();
  const amplitude = useAmplitude();
  const { menuType } = useCart();
  const flags = useFlags();
  const [showCategoriesBorder, setShowCategoriesBorder] = useState(true);
  const dispensaryId = dispensary?.id;

  // don't show anonymous checkout for medical menus/orders ENG-52870
  const useAnonymousKioskCheckout = dispensary?.orderTypesConfigV2.kiosk?.anonymousCheckout && menuType === `rec`;

  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const hasValidSponsoredBrandPlacement = useHasValidSponsoredBrandPlacement('home-page');

  const productsForHomePage = useProductsForHomePage({ dispensaryId });
  const { personalizedProductsQuery, sponsoredBrandQuery } = productsForHomePage;
  const personalizedProducts = personalizedProductsQuery?.data?.getPersonalizedProducts?.products;
  const sponsoredBrandProducts = sponsoredBrandQuery?.data?.getSponsoredBrand?.products;

  // Don't use Categories bottom border if an ads or personalization carousel is loading below it
  useEffect(() => {
    if (
      (personalizedProducts?.length > 0 && personalizationCarouselPlacement === 'secondary') ||
      sponsoredBrandProducts?.length > 0
    ) {
      setShowCategoriesBorder(false);
    }
  }, [personalizedProducts, sponsoredBrandProducts, personalizationCarouselPlacement]);

  useEffect(() => {
    amplitude.log(AmplitudeEvents.menu.viewHomepage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DOMParser = typeof window !== 'undefined' ? window.DOMParser : null;
  const bannerIsBlank = useMemo(
    () => !dispensary?.menuBannerHtml || isBlank(getHtmlPlainText(dispensary?.menuBannerHtml, DOMParser)),
    [dispensary?.menuBannerHtml, DOMParser]
  );

  const showMenuBanner =
    !bannerIsBlank && UI.isVariant([`store-front`, `embedded`, ...(useAnonymousKioskCheckout ? [`kiosk`] : [])]);

  if (!dispensary) {
    return null;
  }

  return (
    <MainContent>
      {!!dispensary?.imageBanners?.length && !UI.isMobileEcommApp && (
        <MenuImageBanner banners={removeTypename(dispensary.imageBanners)} />
      )}

      <NoSsr>
        {showMenuBanner && <MenuBanner htmlContent={dispensary.menuBannerHtml} color={dispensary.menuBannerColor} />}
      </NoSsr>

      {personalizationCarouselPlacement === 'primary' && !UI.isKiosk && (
        <PersonalizedProducts {...productsForHomePage} />
      )}

      <div data-testid='homepage-categories'>
        <MobileOnly mediaQuery='xs' display='block'>
          <CategoryTilesGrid categories={categories} mt='31px' mb='40px' />
          {showCategoriesBorder && <HorizontalDivider />}
        </MobileOnly>

        <DesktopOnly mediaQuery='sm'>
          <CategoriesCarouselSlider borderBottom={showCategoriesBorder} />
        </DesktopOnly>
      </div>

      {personalizationCarouselPlacement === 'secondary' && !UI.isKiosk && (
        <PersonalizedProducts {...productsForHomePage} />
      )}

      {hasValidSponsoredBrandPlacement && (
        <SponsoredBrandCarousel sponsoredBrandQuery={sponsoredBrandQuery} fullWidth />
      )}

      <HomepageCarouselSections dispensary={dispensary} />
    </MainContent>
  );
}
